import request from '../utils/request'

export function getRoles() {
  return request({
    url: '/roles/list',
    method: 'get'
  })
}

export function deleteRoles(data) {
  return request({
    url: '/roles/delete',
    method: 'post',
    data
  })
}

export function editRoles(data) {
  return request({
    url: '/roles/edit',
    method: 'post',
    data
  })
}

export function addRoles(data) {
  return request({
    url: '/roles/add',
    method: 'post',
    data
  })
}