import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { login } from "../../store/actions";
import { InputGroupIconField } from '../../components/input';
import Button from '../../components/button';
import './index.css';

const errorsMessage = {
  username: {
    'required': { color: 'error', text: '请填写用户名' },
    'minLength': { color: 'error', text: '6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址' },
    'maxLength': { color: 'error', text: '6~18个字符，可使用字母、数字、下划线, 手机号码, 邮箱地址' },
    'invalid': { color: 'error', text: '无效数据, 请更换用户名' },
  },
  password: {
    'required': { color: 'error', text: '请填写密码' },
    'minLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
    'maxLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
    'invalid': { color: 'error', text: '无效数据，请更换密码' },
  },
}

const validateUsername = (value) => {
  if (!value || value === '' || value === 'undefined') {
    return 'required';
  }
  if (value.length < 5) {
    return 'minLength';
  }
  if (value.length > 18) {
    return 'maxLength';
  }
  return '';
}

const validatePassword = (value) => {
  if (!value || value === '' || value === 'undefined') {
    return 'required';
  }
  if (value.length < 6) {
    return 'minLength';
  }
  if (value.length > 20) {
    return 'maxLength';
  }
  return '';
}

const Login = ({ isAuthenticated, login }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(()=>{
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  })

  const handleLogin = () => {
    let isValid = false;
    const fieldForm = ['username', 'password'];
    fieldForm.forEach(field => {
      if (!validateField(field)) {
        isValid = true
      }
    })
    if (isValid) {
      return
    }
    login({ ...values }).then((response) => {

    })
  }

  const handleChange = (fieldName) => (event) => {
    setErrors({})
    setValues(pre => { return { ...pre, [fieldName]: event.target.value } });
  }

  const validateField = (fieldName) => {
    let hasError = ''
    switch (fieldName) {
      case 'username':
        hasError = validateUsername(values?.[fieldName]);
        setErrors(pre => { return { ...pre, [fieldName]: hasError ? errorsMessage?.[fieldName]?.[hasError] : '' } })
        break;
      case 'password':
        hasError = validatePassword(values?.[fieldName]);
        setErrors(pre => { return { ...pre, [fieldName]: hasError ? errorsMessage?.[fieldName]?.[hasError] : '' } })
        break;
      default:
        break;
    }
    if (!!hasError) {
      return false
    }

    return true;
  }

  useEffect(() => {
  }, [errors]);

  return (
    <div className="layout-login-container">
      <div className="login-form-container">
        <div className='login-form'>
          <h1 className="login-title">Sign In</h1>
          <InputGroupIconField type="text" icon={<PersonIcon />} onAlert={errors?.['username']} onChange={handleChange('username')} placeholder="Username" />
          <InputGroupIconField type="password" icon={<LockIcon />} onAlert={errors?.['password']} onChange={handleChange('password')} placeholder="Password" />
          <div className="login-input-checkbox">
            <input type="checkbox" name="checkbox" className="check-box" />
            <label htmlFor="checkbox">Remember password</label>
          </div>
          <Button onClick={handleLogin} text={"Sign In"} />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state.auth, { login })(Login);
