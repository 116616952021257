import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import { CustomEditTool, CustomDeleteTool, CustomCreateTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getUserGroups } from '../../api/usergroup';
import columnsIndex from '../../components/dataIndex/usergroupsinfo';

const UserGroup = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const onEdit = (record) => {
    console.log("onEdit", record);
  };

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    setDataSource(_data);
  };

  const onCreate = (record) => {
    console.log("onCreate");
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomEditTool columns={columnsIndex} dataSource={record} onOk={onEdit} title='Edit User Group' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getUserGroups().then((data) => {
      setDataSource(data.data);
    })
  }
  useEffect(() => {
    fetchData();
  
    return () => {
      setDataSource({});
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User Group
        </Typography>
        <CustomCreateTool columns={columnsIndex} dataSource={data} onOk={onCreate} title='New User Group' text='New User Group' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='name' placeholder='Search user group...' />
    </Container>
  )
};

export default UserGroup;
