import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import { CustomEditTool, CustomRenewTool, CustomDeleteTool, CustomCreateTool, CustomViewTool, CustomAlertTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getServers, renewServers, editServers, addServers, deleteServers } from '../../api/server';
import request from '../../utils/request';
import columnsIndex from '../../components/dataIndex/serverinfo';

const Server = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [resource, setResource] = useState({});
  const [visible, setVisible] = useState(false);

  const onEdit = (record) => {
    editServers({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(pre => {
          return pre.map(item => {
            if (item?.id === record?.id) {
              return record
            }
            return item
          })
        })
      } else {
        setError("error");
      }
      setVisible(true);
    })
  };

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    deleteServers({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(_data);
      } else {
        setError("error");
      }
      setVisible(true);
    })
  };

  const onCreate = (record) => {
    addServers({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(pre => {
          return [...pre, { ...record }]
        })
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const onRenew = (record) => {
    console.log("renew", record);
    renewServers({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomViewTool columns={columnsIndex} dataSource={record} title='View Server' />
            <CustomEditTool columns={columnsIndex} resource={resource} dataSource={record} onOk={onEdit} title='Edit Server' />
            <CustomRenewTool dataSource={record} onOk={onRenew} title='Auto Renew' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getServers().then((data) => {
      setDataSource(data.data);
    })
    columnsIndex?.forEach(item => {
      if (item?.renderType === 'Select') {
        item?.['sourceApi'] && request({ ...item?.['sourceApi'] }).then((response) => {
          const { code } = response;
          if (code === 200) {
            const { data } = response;
            setResource(pre => { return { ...pre, [item?.['field']]: data } });
          }
        })
      }
    })
  }
  useEffect(() => {
    fetchData();

    return () => {
      setDataSource({});
      setResource({});
      setError("");
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Server
        </Typography>
        <CustomCreateTool columns={columnsIndex} resource={resource} dataSource={data} onOk={onCreate} title='New Server' text='New Server' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='host' placeholder='Search server...' />
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
    </Container>
  )
};

export default Server;
