import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import AppsIcon from '@mui/icons-material/Apps';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SettingsIcon from '@mui/icons-material/Settings';

const menuModules = [
    { name: "Dashboard", to: "/dashboard", icon: <HomeIcon />, roles: ["admin", "dev"] },
    { name: "Contact", to: "/contact", icon: <MessageIcon />, roles: ["admin", "dev"] },
    { name: "Shop", to: "/shop", icon: <ShoppingCartIcon />, roles: ["admin", "dev"] },
    { name: "Order", to: "/order", icon: <AttachMoneyIcon />, roles: ["admin", "dev"] },
    { name: "Team", to: "/team", icon: <BusinessIcon />, roles: ["admin", "dev"] },
    { name: "用户列表", to: "/user", icon: <AccountCircleIcon />, roles: ["admin", "dev"] },
    { name: "用户组", to: "/user/group", icon: <GroupIcon />, roles: ["admin", "dev"] },
    { name: "供应商", to: "/provider", icon: <StoreIcon />, roles: ["admin", "dev"] },
    { name: "节点", to: "/node", icon: <AppsIcon />, roles: ["admin", "dev"] },
    { name: "服务器", to: "/server", icon: <ComputerIcon />, roles: ["admin", "dev"] },
    { name: "角色", to: "/role", icon: <PersonIcon />, roles: ["admin", "dev"] },
    { name: "权限", to: "/permissions", icon: <PersonPinIcon />, roles: ["admin", "dev"] },
    { name: "个人中心", to: "/profile", icon: <SettingsIcon />, roles: ["admin", "dev"] },
]

export default menuModules;