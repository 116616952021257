const columnsIndex = [
  {
    label: '用户编号',
    field: 'id',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '用户名',
    field: 'username',
    renderType: 'Input',
    hidden: false,
    visible: false,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '密码',
    field: 'password',
    renderType: 'Input',
    hidden: true,
    visible: false,
    writable: true,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: '节点',
    field: 'server',
    renderType: 'Select',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
    keyValueField: 'host',
    sourceApi: {url: '/servers/lite', method: 'get'},
  },
  {
    label: '密钥',
    field: 'secret',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '角色',
    field: 'role',
    renderType: 'Select',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
    keyValueField: 'name',
    sourceApi: {url: '/user/groups/lite', method: 'get'},
  },
  {
    label: '会员等级',
    field: 'level',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Email',
    field: 'email',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '电话号码',
    field: 'phone',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '姓名',
    field: 'name',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '订阅代码',
    field: 'subscribe_code',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '推荐码',
    field: 'referrer_code',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '状态',
    field: 'status',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '到期时间',
    field: 'expires',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '创建时间',
    field: 'created_at',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
];

export default columnsIndex;