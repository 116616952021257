import React, { useState, useEffect } from 'react';
import {
    Card,
    Table,
    TableContainer,
    TableBody,
    TablePagination,
} from '@mui/material';
import CustomHead from './custom/header';
import CustomToolbar from './custom/toolbar';
import CustomRow from './custom/row';

const applySortFilter = (rowKey, query, array) => {
    if (!array) {
        return array;
    }
    const stabilizedThis = array?.map((el, index) => [el, index]);
    if (query) {
        return array?.filter(item => item?.[rowKey]?.toLowerCase().includes(query?.toLowerCase()))
    }
    return stabilizedThis?.map((el) => el[0]);
}

const CustomTable = ({ rows, columns, rowKey = 'field', query, placeholder }) => {
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows?.map(item => item?.[query]);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByValue = (event) => {
        setFilterValue(event.target.value);
    };

    const filtered = applySortFilter(query, filterValue, rows);

    useEffect(() => {
        return () => {
            setFilterValue({});
            setSelected([]);
        }
    }, [])

    return (
        <Card>
            <CustomToolbar
                selected={selected?.length}
                value={filterValue}
                onChange={handleFilterByValue}
                placeholder={placeholder}
            />
            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <CustomHead
                        columns={columns}
                        rowCount={filtered?.length ? filtered?.length : 0}
                        numSelected={selected?.length ? selected?.length : 0}
                        onChange={handleSelectAllClick}
                        style={{ background: '#4285F4', color: '#000' }}
                    />
                    <TableBody>
                        {
                            filtered && filtered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                return <CustomRow selected={selected} setSelected={setSelected} rowKey={rowKey} query={query} row={row} columns={columns} key={index} />
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filtered?.length ? filtered?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    );
};

export default CustomTable;
