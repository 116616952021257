import axios from "axios";
import store from "../store";
import { getToken } from './auth';
import { logout } from "../store/actions";


//创建一个axios示例
const service = axios.create({
    baseURL: '/api/v1', // api 的 base_url
    timeout: 5000, // request timeout
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        if (store.getState().auth.token) {
            // 让每个请求携带token-- ['Authorization']为自定义key 请根据实际情况自行修改
            config.headers.Authorization = getToken();
        }
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const { status, data } = response;
        if (status === 200) {
            const { code } = data;
            if ( code === 401) {
                store.dispatch(logout());
            }
            return data;
        }
        
        console.log("err" , status, data);
        return Promise.reject(data);
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

export default service;