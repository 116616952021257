import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import { CustomEditTool, CustomDeleteTool, CustomCreateTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getPermissions } from '../../api/permissions';

const columnsIndex = [
  {
    label: 'Id',
    field: 'id',
    renderType: 'Input',
    hidden: false,  // show is on/off
    visible: false,  // create is reader
    writable: false,  // create is writer
    updateWritable: false,  // 可写
    updateVisible: true,  // 可读
  },
  {
    label: 'Name',
    field: 'name',
    renderType: 'Input',
    hidden: false,
    visible: true,
    require: true,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Alias',
    field: 'alias',
    renderType: 'Input',
    hidden: false,
    visible: true,
    require: true,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Content',
    field: 'content',
    renderType: 'Input',
    hidden: false,
    visible: false,
    require: false,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Description',
    field: 'description',
    renderType: 'Input',
    hidden: false,
    visible: true,
    require: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
];

const Permissions = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const onEdit = (record) => {
    console.log("onEdit", record);
  };

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    setDataSource(_data);
  };

  const onCreate = (record) => {
    console.log("onCreate");
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomEditTool columns={columnsIndex} dataSource={record} onOk={onEdit} title='Edit User' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getPermissions().then((response) => {
      const { code, data } = response;
      if (code === 200) {
        data && setDataSource(data);
      }
    })
  }
  useEffect(() => {
    fetchData();
  
    return () => {
      setDataSource({});
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Permissions
        </Typography>
        <CustomCreateTool columns={columnsIndex} dataSource={data} onOk={onCreate} title='New Permissions' text='New Permissions' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='name' placeholder='Search permissions...' />
    </Container>
  )
};

export default Permissions;
