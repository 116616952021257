import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
} from '@mui/material';
import { InputGroupIconField } from '../../../components/input';
import CustomDialog from '../../dialog';
import LockIcon from '@mui/icons-material/Lock';

const errorsMessage = {
    password: {
        'required': { color: 'error', text: '请填写密码' },
        'minLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'maxLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'invalid': { color: 'error', text: '无效数据，请重新输入' },
    },
    newPassword: {
        'required': { color: 'error', text: '请填写新密码' },
        'minLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'maxLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'invalid': { color: 'error', text: '无效数据，请更换密码' },
    },
    confirmPassword: {
        'required': { color: 'error', text: '请填写确认密码' },
        'minLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'maxLength': { color: 'error', text: '密码长度应为6~20个字符，必需由英文字母、数字或特殊符号组成' },
        'invalid': { color: 'error', text: '两次填写的密码不一致' },
    },
}

const validatePassword = (value) => {
    if (!value || value === '' || value === 'undefined') {
        return 'required';
    }
    if (value.length < 6) {
        return 'minLength';
    }
    if (value.length > 20) {
        return 'maxLength';
    }
    return '';
}

const validateNewPassword = (value, password) => {
    if (!value || value === '' || value === 'undefined') {
        return 'required';
    }
    if (value.length < 6) {
        return 'minLength';
    }
    if (value.length > 20) {
        return 'maxLength';
    }
    if (value === password) {
        return 'invalid';
    }
    return '';
}

const validateConfirmPassword = (value, password) => {
    if (!value || value === '' || value === 'undefined') {
        return 'required';
    }
    if (value !== password) {
        return 'invalid';
    }
    return '';
}

const CustomPassword = ({ divider = true, onClose = true, onOk, cancelText = 'cancel', okText = 'ok', title = 'Set New Password', tooltip = 'Set Password', Text = '' }) => {
    const [visible, setVisible] = useState(false);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setValues(pre => { return { } });
        return () => {
            setValues(pre => { })
        }
    }, [visible])

    const handleChange = (fieldName) => (event) => {
        setErrors({})
        setValues(pre => { return { ...pre, [fieldName]: event.target.value } });
    }

    const handleOk = () => {
        let isValid = false;
        const fieldForm = ['password', 'newPassword', 'confirmPassword'];
        fieldForm.forEach(field => {
            if (!validateField(field)) {
                isValid = true
            }
        })
        if (isValid) {
            return
        }
        setVisible(false);
        onOk(values);
    }

    const validateField = (fieldName) => {
        let hasError = ''
        switch (fieldName) {
            case 'password':
                hasError = validatePassword(values?.[fieldName]);
                setErrors(pre => { return { ...pre, [fieldName]: hasError ? errorsMessage?.[fieldName]?.[hasError] : '' } })
                break;
            case 'newPassword':
                hasError = validateNewPassword(values?.[fieldName], values?.['password']);
                setErrors(pre => { return { ...pre, [fieldName]: hasError ? errorsMessage?.[fieldName]?.[hasError] : '' } })
                break;
            case 'confirmPassword':
                hasError = validateConfirmPassword(values?.[fieldName], values?.['newPassword']);
                setErrors(pre => { return { ...pre, [fieldName]: hasError ? errorsMessage?.[fieldName]?.[hasError] : '' } })
                break;
            default:
                break;
        }
        if (!!hasError) {
            return false
        }

        return true;
    }

    useEffect(() => {
    }, [errors]);

    return (
        <>
            <Button variant="contained" onClick={() => { setVisible(true) }}>{Text}</Button>
            <CustomDialog onClose={onClose} divider={divider} visible={visible} onOk={handleOk} onCancel={() => { setVisible(false) }} cancelText={cancelText} okText={okText} title={title} maxWidth='sm'>
                <Box sx={{ width: 500 }}>
                    <div className="custom-input-label">password</div>
                    <InputGroupIconField type={'password'} icon={<LockIcon />} onAlert={errors?.['password']} onChange={handleChange('password')} placeholder='Password' />
                    <div className="custom-input-label">New password</div>
                    <InputGroupIconField type={'password'} icon={<LockIcon />} onAlert={errors?.['newPassword']} onChange={handleChange('newPassword')} placeholder='New Password' />
                    <div className="custom-input-label">Confirm password</div>
                    <InputGroupIconField type={'password'} icon={<LockIcon />} onAlert={errors?.['confirmPassword']} onChange={handleChange('confirmPassword')} placeholder='Confirm Password' />
                </Box>
            </CustomDialog>
        </>
    );
}

export default CustomPassword;
