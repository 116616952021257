import React from 'react';
import { Checkbox, TableRow, TableCell, TableHead } from '@mui/material';

const CustomHead = ({ rowCount, columns, numSelected, onChange, style }) => {
    return (
        <TableHead>
            <TableRow sx={{
                '& .MuiTableCell-head': {
                    bgcolor:'#4285F4',
                    color: '#fff',
                    fontWeight: 600
                },
            }}>
                <TableCell padding="checkbox" >
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onChange}
                        sx={{color: '#fff'}}
                    />
                </TableCell>
                {columns.map((column, index) => (
                    <TableCell
                        color="primary"
                        key={index}
                        align={column?.alignRight ? 'right' : 'left'}
                    >
                        {column?.hidden ? '' : column?.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default CustomHead;
