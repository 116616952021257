import React from 'react';
import {
    TableRow,
    TableCell,
    Checkbox,
} from '@mui/material';

const customTableCell = (rowKey, row, columns) => {
    return (
        columns.map((column, index) => {
            return (
                <TableCell align="left" key={index}>
                    {
                        column?.hidden ? '' : column?.render ?  column?.render({...row}) : row?.[column?.[rowKey]]
                    }
                </TableCell>
            );
        })
    );
};

const handleClick = (event, keyword, selected, setSelected) => {
    const selectedIndex = selected.indexOf(keyword);
    let newSelected = [];
    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, keyword);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
        );
    }
    setSelected(newSelected);
};

const CustomRow = ({ selected, setSelected, rowKey, query, row, columns }) => {
    const isItemSelected = selected.indexOf(row?.[query]) !== -1;
    return (
        <TableRow
            hover
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handleClick(event, row?.[query], selected, setSelected)}
                />
            </TableCell>
            {customTableCell(rowKey, row, columns)}
        </TableRow>
    );
};

export default CustomRow;
