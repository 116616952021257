import request from '../utils/request';

export function reqLogin(data) {
  return request({
    url: '/login/admin',
    method: 'post',
    data
  });
}

export function reqLogout() {
  return request({
    url: '/logout',
    method: 'post',
  });
}