import request from '../utils/request'

export function getUserGroups() {
  return request({
    url: '/user/groups/list',
    method: 'get'
  })
}

export function deleteUserGroups(data) {
  return request({
    url: '/user/groups/delete',
    method: 'post',
    data
  })
}

export function editUserGroups(data) {
  return request({
    url: '/user/groups/edit',
    method: 'post',
    data
  })
}

export function addUserGroups(data) {
  return request({
    url: '/user/groups/add',
    method: 'post',
    data
  })
}