import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const MenuItem = ({ name, icon, subMenus, to }) => {
    return (
        <li className={`sider-nav-item`}>
            <Link to={to} className={`sider-nav-link`}>
                <i>{icon}</i>
                <span>{name}</span>
            </Link>
            <span className="sider-nav-tooltip">{name}</span>
            {
                subMenus && subMenus.length > 0 ? (
                    <ul className={`sub-menu`}>
                        {
                            subMenus.map((menu, index) => (
                                <li key={index}>
                                    <NavLink to={(menu.to)} className='sub-item'>{menu.name}</NavLink>
                                </li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </li>
    )
}

export default MenuItem;
