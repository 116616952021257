import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import {  CustomDeleteTool, CustomCreateTool, CustomViewTool, CustomAlertTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getContactList, deleteContact } from '../../api/contact';
import columnsIndex from '../../components/dataIndex/contactinfo';

const Contact = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [resource, setResource] = useState({});
  const [visible, setVisible] = useState(false);

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    deleteContact({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(_data);
      } else {
        setError("error");
      }
      setVisible(true);
    })
  };

  const onCreate = (record) => {
    console.log("onCreate");
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomViewTool columns={columnsIndex} dataSource={record} title='View User' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getContactList().then((response) => {
      const { code } = response;
      if (code === 200) {
        const { data } = response;
        setDataSource(data);
      }
    })
  }
  useEffect(() => {
    fetchData();
    return () => {
      setResource({});
      setDataSource({});
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Contact
        </Typography>
        <CustomCreateTool columns={columnsIndex} resource={resource} dataSource={data} onOk={onCreate} title='New Contact' text='New Contact' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='id' placeholder='Search Contact...' />
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
    </Container>
  )
};

export default Contact;