import request from '../utils/request'

export function getContactList() {
  return request({
    url: '/contact/list',
    method: 'get'
  })
}

export function deleteContact(data) {
  return request({
    url: '/contact/delete',
    method: 'post',
    data
  })
}