import React, { useState, useEffect } from 'react';
import {
    Box,
    Tooltip,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    MenuItem,
    Select,
    OutlinedInput,
    InputLabel,
    FormControl,
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomDialog from '../dialog';
import request from '../../utils/request';

const CustomEditTool = ({ columns, resource = {}, dataSource = {}, divider = true, onClose = true, onOk, cancelText = 'cancel', okText = 'save', title = 'Edit' }) => {
    const [visible, setVisible] = useState(false);
    const [values, setValues] = useState({});
    const [selectValues, setSelectValues] = useState({});

    useEffect(() => {
        setValues(pre => { return { ...dataSource } });
        setSelectValues(pre => { return { ...resource } });
        return () => {
            setValues(pre => { });
            setSelectValues(pre => { });
        }
    }, [visible])

    const handleOk = () => {
        setVisible(false);
        onOk(values);
    }

    const handleChange = (fieldName) => (event) => {
        setValues(pre => { return { ...pre, [fieldName]: event.target.value } });
    }

    return (
        <>
            <Tooltip title="Edit">
                <IconButton onClick={() => { setVisible(true); }}>
                    <EditIcon sx={{ color: '#176BEF' }} />
                </IconButton>
            </Tooltip>
            <CustomDialog onClose={onClose} divider={divider} visible={visible} onOk={handleOk} onCancel={() => { setVisible(false) }} cancelText={cancelText} okText={okText} title={title} maxWidth='sm'>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }}>
                    {
                        columns.map((item, index) => {
                            if (item?.renderType === 'Select') {
                                return selectValues?.[item?.['field']] && (
                                    <FormControl key={index} sx={{ m: 1, width: '100%' }}>
                                        <InputLabel>{item?.['label']}</InputLabel>
                                        <Select
                                            value={values?.[item?.['field']]}
                                            label={item?.['label']}
                                            onChange={handleChange(item?.['field'])}
                                        >
                                            {selectValues?.[item?.['field']]?.map((v, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={v?.[item?.['keyValueField']]}
                                                >
                                                    {v?.[item?.['keyValueField']]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }
                            return (
                                item?.updateVisible === false ? '' : item?.updateWritable ? (<TextField key={index} onChange={handleChange(item?.['field'])} label={item?.label} defaultValue={dataSource?.[item?.field]} />) : (<TextField key={index} disabled label={item?.label} defaultValue={dataSource?.[item?.field]} />)
                            )
                        })
                    }
                </Box>
            </CustomDialog>
        </>
    );
};

const CustomRenewTool = ({ dataSource, onOk, onClose = false, title = 'Renew' }) => {
    const [visible, setVisible] = useState(false);

    const handleOk = () => {
        onOk(dataSource);
        setVisible(false);
    }

    return (
        <>
            <Tooltip title={title}>
                <IconButton onClick={() => { setVisible(true) }}>
                    <AutorenewIcon sx={{ color: '#1DA462' }} />
                </IconButton>
            </Tooltip>
            <CustomDialog onClose={onClose} visible={visible} okText={'OK'} onOk={() => { handleOk() }} onCancel={() => { setVisible(false) }} title={title}>Are you sure to renew this?</CustomDialog>
        </>
    )
}

const CustomDeleteTool = ({ dataSource, onOk, onClose = false, title = 'Delete' }) => {
    const [visible, setVisible] = useState(false);

    const handleOk = () => {
        onOk(dataSource);
        setVisible(false);
    }

    return (
        <>
            <Tooltip title={title}>
                <IconButton onClick={() => { setVisible(true) }}>
                    <DeleteIcon sx={{ color: '#ff2424' }} />
                </IconButton>
            </Tooltip>
            <CustomDialog onClose={onClose} visible={visible} okText={'Delete'} onOk={() => { handleOk() }} onCancel={() => { setVisible(false) }} title={title}>Are you sure to delete this?</CustomDialog>
        </>
    )
}

const CustomCreateTool = ({ columns, resource = {}, dataSource = {}, divider = true, onClose = true, onOk, cancelText = 'cancel', okText = 'create', title = 'New', text = 'New' }) => {
    const [visible, setVisible] = useState(false);
    const [values, setValues] = useState({});
    const [selectValues, setSelectValues] = useState({});

    useEffect(() => {
        setValues(pre => { return { ...dataSource } });
        setSelectValues(pre => { return { ...resource } });
        return () => {
            setValues(pre => { });
            setSelectValues(pre => { });
        }
    }, [visible])

    const handleChange = (fieldName) => (event) => {
        setValues(pre => { return { ...pre, [fieldName]: event.target.value } });
    }

    const handleOk = () => {
        setVisible(false);
        onOk(values);
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={() => { setVisible(true); }}
                startIcon={<AddIcon />}
                color="primary"
            >
                {text}
            </Button>
            <CustomDialog onClose={onClose} divider={divider} visible={visible} onOk={() => { handleOk() }} onCancel={() => { setVisible(false) }} cancelText={cancelText} okText={okText} title={title} maxWidth='sm'>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }}>
                    {
                        columns.map((item, index) => {
                            if (item?.renderType === 'Select') {
                                return selectValues?.[item?.['field']] && (
                                    <FormControl key={index} sx={{ m: 1, width: '100%' }}>
                                        <InputLabel>{item?.['label']}</InputLabel>
                                        <Select
                                            value={values?.[item?.['field']] ? values?.[item?.['field']] : ''}
                                            label={item?.['label']}
                                            onChange={handleChange(item?.['field'])}
                                        >
                                            {selectValues?.[item?.['field']]?.map((v, key) => (
                                                <MenuItem
                                                    key={key}
                                                    value={v?.[item?.['keyValueField']]}
                                                >
                                                    {v?.[item?.['keyValueField']]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }
                            return (
                                item?.writable ? (<TextField key={index} onChange={handleChange(item?.['field'])} label={item?.label} defaultValue={dataSource?.[item?.field]} />) : ''
                            )
                        })
                    }
                </Box>
            </CustomDialog>
        </>
    );
}

const CustomViewTool = ({ columns, dataSource, divider = true, onClose = true, cancelText = 'cancel', okText = 'ok', title = 'View', text = 'View', tooltip = 'View' }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton onClick={() => { setVisible(true) }}>
                    <VisibilityIcon sx={{ color: '#6A7279' }} />
                </IconButton>
            </Tooltip>
            <CustomDialog onClose={onClose} divider={divider} visible={visible} onOk={() => { setVisible(false) }} onCancel={() => { setVisible(false) }} cancelText={cancelText} okText={okText} title={title} maxWidth='sm'>
                <Box sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, }}>
                    {
                        columns.map((item, index) => {
                            return (
                                item?.visible ? (<TextField key={index} disabled label={item?.label} defaultValue={dataSource?.[item?.field]} />) : ''
                            )
                        })
                    }
                </Box>
            </CustomDialog>
        </>
    );
}

const CustomAlertTool = ({ visible = 'false', setVisible, okText = 'OK', onError= 'error' }) => {
    const errorIconButton = {
        'success' : {
            icon: <CheckCircleOutlineIcon sx={{ width: 100, height: 100, color: '#4CAF50' }} />,
            text: '成功',
        },
        'error' : {
            icon: <CancelIcon sx={{ width: 100, height: 100, color: '#FF3333' }} />,
            text: '失败',
        },
    }
    return (
        <>
            <Dialog
                open={visible}
            >
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                            textAlign: 'center',
                        }}
                    >
                        {errorIconButton?.[onError]?.['icon'] }
                        <p>{errorIconButton?.[onError]?.['text']}</p>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { setVisible(false) }}>{okText}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const CustomQrCode = ({ url, okText = 'OK' }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Tooltip title="QrCode">
                <IconButton onClick={() => { setVisible(true) }}>
                    <QrCodeIcon sx={{ color: '#176BEF' }} />
                </IconButton>
            </Tooltip>
            <Dialog
                open={visible}
            >
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={url}
                            alt="qrcode"
                            loading="lazy"
                        />
                        <Button variant="contained" onClick={() => { setVisible(false) }}>{okText}</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

const CustomDownloadTool = ({ url }) => {
    return (
        <>
            <Tooltip title="ClashForAndroid">
                <a href={url}>
                    <IconButton>
                        <FileDownloadIcon sx={{ color: '#6A7279' }} />
                    </IconButton>
                </a>
            </Tooltip>
        </>
    )
}

export {
    CustomEditTool,
    CustomRenewTool,
    CustomDeleteTool,
    CustomCreateTool,
    CustomViewTool,
    CustomAlertTool,
    CustomQrCode,
    CustomDownloadTool,
};
