import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import routeModules from '../../../config/routeMap';

const Content = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
      <Routes>
        {
          routeModules.map(v => {
            return (
              (v.path === pathname) && <Route key={v.path} path={v.path} element={v.component} />
            )
          })
        }
      </Routes>
  );
};

export default Content;
