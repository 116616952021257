import request from '../utils/request'

export function reqUserInfo(data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function getUsers() {
  return request({
    url: '/users/list',
    method: 'get'
  })
}

export function deleteUser(data) {
  return request({
    url: '/users/delete',
    method: 'post',
    data
  })
}

export function editUser(data) {
  return request({
    url: '/users/edit',
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/users/add',
    method: 'post',
    data
  })
}

export function editPassword(data) {
  return request({
    url: '/users/edit/password',
    method: 'post',
    data: data
  })
}

export function editServer(data) {
  return request({
    url: '/users/edit/server',
    method: 'post',
    data
  })
}