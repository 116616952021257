import { reqLogin, reqLogout } from "../../api/auth";
import { setToken, removeToken } from "../../utils/auth";
import { setUserInfo, resetUser } from "./user";

export const login = ({ username, password }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        reqLogin({ username, password }).then((response) => {
            const { code } = response;
            if (code === 200) {
                const { data } = response;
                const { accessToken } = data;
                dispatch(setUserInfo({ token: accessToken, user: { ...data } }));
                setToken(accessToken);
                resolve(data);
            } else {
                reject(response);
            }
        }).catch((error) => {
            reject(error);
        });
    });
};

export const logout = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        reqLogout().then((response) => {
            dispatch(resetUser());
            removeToken();
            resolve(response);
        }).catch((error) => {
            dispatch(resetUser());
            removeToken();
            reject(error);
        });
    });
};