import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

const CustomTitle = ({ children, onClose }) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CustomDialog = ({ divider = false, visible = false, onClose = true, onCancel, onOk, cancelText = 'Cancel', okText = 'Ok', title = 'Title', maxWidth = 'sm', children }) => {
    return (
        <Dialog
            maxWidth={maxWidth}
            open={visible}
        >
            <CustomTitle onClose={onClose ? onCancel : null}>{title}</CustomTitle>
            {divider && <Divider />}
            <DialogContent>{children}</DialogContent>
            {divider && <Divider />}
            <DialogActions>
                <Button variant="outlined" autoFocus onClick={onCancel}>
                    {cancelText}
                </Button>
                <Button variant="contained" onClick={onOk}>{okText}</Button>
            </DialogActions>
        </Dialog>
    )
}; 

export default CustomDialog;
