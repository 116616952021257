import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import { CustomDeleteTool, CustomCreateTool, CustomViewTool, CustomAlertTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getOrders, addOrders } from '../../api/order';
import columnsIndex from '../../components/dataIndex/userorderinfo';

const Order = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);

  const onEdit = (record) => {
    console.log("onEdit", record);
  };

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    setDataSource(_data);
  };

  const onCreate = (record) => {
    addOrders({ ...record }).then((response) => {
      const {code} = response;
      if (code === 200) {
        setError("success");
        setDataSource(pre => {
          return [...pre, { ...record }]
        })
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomViewTool columns={columnsIndex} dataSource={record} title='View User' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getOrders().then((response) => {
      const { code, data } = response;
      if (code === 200) {
        data && setDataSource(data);
      }
    })
  }
  useEffect(() => {
    fetchData();

    return () => {
      setDataSource(null);
      setError("");
    };
  }, []);


  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Order
        </Typography>
        <CustomCreateTool columns={columnsIndex} dataSource={data} onOk={onCreate} title='New Order' text='New Order' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='username' placeholder='Search order...' />
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
    </Container>
  )
};

export default Order;
