import React, { useState } from 'react';
import CustomPassword from '../../components/input/custom/password';
import { CustomAlertTool } from '../../components/toolbar';
import {editPassword} from '../../api/admin';

const errorsMessage = {
  username: {
    'required': { color: 'error', text: 'username is required' },
    'minLength': { color: 'error', text: 'username is must be at least 5 characters' },
    'maxLength': { color: 'error', text: 'The username is must be a maximum of 12 characters' },
    'invalid': { color: 'error', text: 'username is invalid' },
  },
  password: {
    'required': { color: 'error', text: 'password is required' },
    'minLength': { color: 'error', text: 'The password is must be at least 8 characters' },
    'maxLength': { color: 'error', text: 'The password is must be a maximum of 30 characters' },
    'invalid': { color: 'error', text: 'password is invalid' },
  },
  confirmPassword: {
    'required': { color: 'error', text: 'confirmPassword is required' },
    'minLength': { color: 'error', text: 'The confirmPassword is must be at least 8 characters' },
    'maxLength': { color: 'error', text: 'The confirmPassword is must be a maximum of 30 characters' },
    'invalid': { color: 'error', text: 'confirmPassword is invalid' },
  },
}

const Profile = () => {
  const [visible, setVisible] = useState(false);


  const onEditPassword = (record) => {
    editPassword({ ...record }).then((response) => {
      console.log(response);
      setVisible(true);
    })
  }

  return (
    <>
      <CustomAlertTool visible={visible} setVisible={setVisible} />
      <CustomPassword Text='修改密码' onOk={onEditPassword} title='New Password' />
    </>
  )
};

export default Profile;