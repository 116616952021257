import React, { useState, useRef } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './base.css';

const colorsTheme = {
    error: {
        color: '#EF5350',
        icon: <ErrorOutlineIcon />,
    },
    warning: {
        color: '#FF9800',
        icon: <WarningAmberIcon />,
    },
    info: {
        color: '#03A9F4',
        icon: <ErrorOutlineIcon />,
    },
    success: {
        color: '#4CAF50',
        icon: <CheckCircleOutlineIcon />,
    },
}

const InputBase = ({ type = 'text', autoComplete = 'off', placeholder = '', onChange, style = {} }) => {
    const ref = useRef(null);
    const [hasFocus, setFocus] = useState(false);
    const [visible, setVisible] = useState(false);
    return (
        <>
            <input
                ref={ref}
                onChange={(e) => { onChange(e) }}
                type={type !== 'password' ? type : visible ? 'text' : 'password'}
                autoComplete={autoComplete}
                placeholder={hasFocus ? '' : placeholder}
                className={type !== 'password' ? 'custom-input' : 'custom-input password'}
                style={{ ...style }}
                onFocus={() => setFocus(true)}
                onBlur={(e) => { setFocus(false); onChange(e) }}
            />
            <div className='custom-tooltip-box' title={visible ? 'hide' : 'show'} style={{ display: type !== 'password' ? 'none' : 'block' }}>
                <button className="custom-tooltip-icon-button" onClick={() => { setVisible(!visible) }}>
                    {visible ? <Visibility /> : <VisibilityOff />}
                </button>
            </div>
        </>
    )
};

const TextField = ({ onChange, type = 'text', label = '', autoComplete = 'off', placeholder = '', style = { height: '50px', margin: '10px 0' } }) => {
    return (
        <>
            <div className='custom-label'>{label}</div>
            <div className='custom-text-input-box' style={{ ...style }}>
                <InputBase type={type} autoComplete={autoComplete} placeholder={placeholder} onChange={onChange} />
            </div>
        </>
    );
}

const InputIconField = ({ icon, onChange, type = 'text', autoComplete = 'off', placeholder = '', style = { height: '50px' } }) => {
    return (
        <div className='custom-icon-input-box' style={{ ...style }}>
            <div className='custom-icon-input-icon'>{icon}</div>
            <InputBase type={type} autoComplete={autoComplete} placeholder={placeholder} onChange={onChange} />
        </div>
    );
};

const InputAlert = ({ color, text }) => {
    const styles = {
        display: text ? 'inline-flex' : 'none',
        color: colorsTheme?.[color]?.color ? colorsTheme?.[color]?.color : 'inherit',
    }

    return (
        <div className='custom-input-alert-box' style={{ ...styles }}>
            <div className='custom-input-alert-icon' style={{ ...styles }}>{text ? colorsTheme?.[color]?.icon : ''}</div>
            <div className='custom-input-alert-text' style={{ ...styles }}>{text}</div>
        </div>
    );
}

const InputGroupIconField = ({ icon, onAlert = {}, onChange, id, type = 'text', autoComplete = 'off', placeholder = '', style = { margin: '10px 0' } }) => {
    return (
        <div className='custom-input-group-field' style={{ ...style }}>
            <InputIconField icon={icon} type={type} id={id} autoComplete={autoComplete} placeholder={placeholder} onChange={onChange} />
            <InputAlert {...onAlert} />
        </div>
    );
}

export {
    InputBase,
    InputAlert,
    TextField,
    InputIconField,
    InputGroupIconField,
};
