import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import { getUserInfo } from '../store/actions';

const ProtectedRoute = ({ token, isAuthenticated, getUserInfo }) => {
    const location = useLocation();

    if (!isAuthenticated && token) {
      getUserInfo(token);
    }
  
    if (isAuthenticated) {
      return location.pathname === "/" ? <Navigate to="/dashboard" state={{ from: location }} replace /> : <Outlet />
    }
  
    return <Navigate to="/login" state={{ from: location }} replace />
  };

export default connect((state) => state.auth, { getUserInfo })(ProtectedRoute);